import { useState } from "react"
import firebaseAppConfig from "../util/firebase-config"
import { signInWithEmailAndPassword, getAuth } from "firebase/auth"
import { Link, useNavigate } from "react-router-dom"
// import sImg from "../components/signup.jpg"


const auth = getAuth(firebaseAppConfig)

const Login = ()=>{
    const navigate = useNavigate()
    const [passwordType, setPasswordType] = useState("password")
    const [error, setError] = useState(null)
    const [loader, setLoader] = useState(false)
    const [formValue, setFormValue] = useState({
        email: '',
        password: ''
    })

    const login = async (e)=>{
        try {
            e.preventDefault()
            setLoader(true)
            await signInWithEmailAndPassword(auth, formValue.email, formValue.password)
            navigate("/")
        }
        catch(err)
        {
            setError("Invalid credentials provided")
        }
        finally {
            setLoader(false)
        }
    }

    const handleChange = (e)=>{
        const input = e.target
        const name = input.name
        const value = input.value
        setFormValue({
            ...formValue,
            [name]: value
        })
        setError(null)
    }

    return (
        <div className="animate__animated animate__fadeIn mt-4 sm:mt-10 container mx-auto">
            {/* <img src={sImg} className="w-full md:h-full h-24 object-cover hidden lg:block" alt="" /> */}
            <div className="flex flex-col sm:p-8 md:p-12 lg:p-16 xl:p-20 max-w-2xl mx-auto mt-10 sm:mt-24 bg-[#7fcdcd] rounded-3xl h-full">
                <h1 className="text-4xl font-bold">Sign-In</h1>
                <p className="text-lg text-white">Enter profile details to login</p>
                <form className="mt-8 space-y-6" onSubmit={login}>
                    <div className="flex flex-col">
                        <label className="font-semibold text-lg mb-1">Email id</label>
                        <input 
                            onChange={handleChange}
                            required
                            type="email"
                            name="email"
                            placeholder="example@mail.com"
                            className="p-3 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="flex flex-col relative">
                        <label className="font-semibold text-lg mb-1">Password</label>
                        <input 
                            onChange={handleChange}
                            required
                            type={passwordType}
                            name="password"
                            placeholder="********"
                            className="p-3 border border-gray-300 rounded"
                        />
                        <button onClick={()=>setPasswordType(passwordType === "password" ? "text" : "password")} type="button" className="absolute top-11 right-4 w-8 h-8 rounded-full hover:bg-blue-200 hover:text-blue-600">
                            {
                                passwordType === "password" ? 
                                <i className="ri-eye-line"></i> 
                                : 
                                <i className="ri-eye-off-line"></i>
                            }
                        </button>
                    </div>
                    {
                        loader ? 
                        <h1 className="text-lg font-semibold text-gray-600">Loading...</h1>
                        :
                        <button className="py-3 px-8 rounded bg-blue-600 text-white font-semibold hover:bg-rose-600">Login</button>
                    }
                </form>
                <div className="mt-2">
                    Don`t have an account ? <Link to="/signup" className="text-blue-600 font-semibold">Register now</Link>
                </div>

                {
                    error && 
                    <div className="flex justify-between items-center mt-2 bg-rose-600 p-3 rounded shadow text-white font-semibold animate__animated animate__pulse">
                        <p>{error}</p>
                        <button onClick={()=>setError(null)}>
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Login