

const Searchfrom = ({ setData }) => {
  const handleChange = (e) => {
    setData(e.target.value);
  };

  const handleSubmit = (e) =>{
e.preventDefault();
  }

  return (
    <div id="mainDiv" className="main-deiv-class m-7 ">
      <div className="flex justify-center sm:mt-48 lg:mt-32 font-bold sm:text-6xl lg:text-6xl leading-10">
        Find Your Perfect <span className="text-green-600">&nbsp;AI Tools&nbsp;</span>with us
      </div>
      <p className="flex justify-center sm:mt-10 lg:mt-5 sm:mb-14 lg:mb-1 leading-10 sm:text-4xl lg:text-2xl lg:font-semibold">
        Find <span className="text-red-600">&nbsp;AI Tools&nbsp;</span> for your work- like Code, Video... {" "}
      </p>

      <form className="sm:max-w-[70%] lg:max-w-md mx-auto" onSubmit={handleSubmit}>   
      <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
          </div>
          <input type="text" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-[10px] bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-white dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " placeholder="Just type AI tool name or work..." onChange={handleChange} required />
          {/* <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
      </div>
    </form>
    </div>
  );
};

export default Searchfrom;





// import React, { useState } from "react";

// const Searchform = ({ setData }) => {
//   const [searchQuery, setSearchQuery] = useState("");

//   const handleChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" || e.target.value.toLowerCase() === "search") {
//       handleSearch();
//     }
//   };

//   const handleSearch = () => {
//     setData(searchQuery.trim());
//   };

//   return (
//     <div id="mainDiv" className="main-deiv-class m-7 ">
//       <div className="flex justify-center sm:mt-48 lg:mt-32 font-bold sm:text-6xl lg:text-6xl leading-10">
//         Find Your Perfect AI Tools with us
//       </div>
//       <p className="flex justify-center sm:mt-10 lg:mt-5 sm:mb-14 lg:mb-1 leading-10 sm:text-4xl lg:text-2xl lg:font-semibold">
//         Search AI Tools for your work- like Code, Video...
//       </p>

//       <form className="max-w-md mx-auto">
//         <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
//           Search
//         </label>
//         <div className="relative flex items-center">
//           <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//             <svg className="w-4 h-4 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
//               <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
//             </svg>
//           </div>
//           <input
//             type="search"
//             id="default-search"
//             className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-white dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//             placeholder="Just Type AI Tools Name or Work..."
//             onChange={handleChange}
//             onKeyPress={handleKeyPress}
//             value={searchQuery}
//             required
//           />
//           <button
//             type="button"
//             className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             onClick={handleSearch}
//           >
//             Search
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Searchform;


