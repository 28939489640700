import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../components/AiLogo.png";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full z-50 lg:h-5 mb-10">
      <div className="text-center bg-[rgb(121,239,255)] sm:text-2xl lg:text-[15px] p-1">
        <p className="lg:font-semibold">
          Latest AI Tools, News & Jobs updated everyday
        </p>
      </div>
      <nav className="flex items-center justify-between flex-wrap bg-[#f8f7f7] font-bold shadow-md px-10 ">
        <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
          <NavLink exact to="/">
            <img
              src={logo}
              className="w-100 mr-2 sm:h-20 sm:w-20 lg:h-12 lg:w-12"
              alt="Logo"
            />
          </NavLink>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <svg
              className={`fill-current h-16 w-16 ${
                isOpen ? "hidden" : "block"
              }`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg
              className={`fill-current h-16 w-16 ${
                isOpen ? "block" : "hidden"
              }`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <div className="sm:gap-4 lg:gap-9 flex sm:text-4xl lg:text-sm lg:flex-grow lg:flex-row sm:flex-col">
            <NavLink
              exact
              to="/"
              className="hover:bg-green-100  rounded-md block p-2"
              activeClassName="bg-green-500"
              onClick={closeNavbar}
            >
              🏠 Home
            </NavLink>
            <NavLink
              to="/news"
              className="hover:bg-green-100 rounded-md block p-2"
              onClick={closeNavbar}
            >
              📰 News
            </NavLink>
            <NavLink
              to="/jobs"
              className="hover:bg-green-100 rounded-md block p-2"
              onClick={closeNavbar}
            >
              💼 Jobs
            </NavLink>
            <NavLink
              to="/allAiTools"
              className="hover:bg-green-100 rounded-md block p-2"
              onClick={closeNavbar}
            >
              🤖 AI Tools
            </NavLink>
          </div>

          <div>
            <div className="md:flex gap-1">
              <div>
                <NavLink to="/signup">
                  <div
                    className="sm:m-4 lg:m-0 inline-flex items-center bg-green-500 border-0 py-2 px-4 text-white rounded-md ml-2"
                    onClick={closeNavbar}
                  >
                    <button className="">Account</button>
                    {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="32"
                height="32"
                fill="currentColor"
              >
                <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12.1597 16C10.1243 16 8.29182 16.8687 7.01276 18.2556C8.38039 19.3474 10.114 20 12 20C13.9695 20 15.7727 19.2883 17.1666 18.1081C15.8956 16.8074 14.1219 16 12.1597 16ZM12 4C7.58172 4 4 7.58172 4 12C4 13.8106 4.6015 15.4807 5.61557 16.8214C7.25639 15.0841 9.58144 14 12.1597 14C14.6441 14 16.8933 15.0066 18.5218 16.6342C19.4526 15.3267 20 13.7273 20 12C20 7.58172 16.4183 4 12 4ZM12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5ZM12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7Z"></path>
              </svg> */}
                  </div>
                </NavLink>
              </div>

              <NavLink to="/contact">
                <button
                  className="sm:m-4 lg:m-0 inline-flex items-center bg-green-500 border-0 py-2 px-4 text-white rounded-md ml-2"
                  onClick={closeNavbar}
                >
                  Contact us
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;

// new navbar code

// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import logo from "../components/logo10.png";

// function Header() {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="fixed top-0 left-0 w-full z-50" >
//       <div className="text-center bg-[rgb(121,239,255)] h-[30px] pt-1">
//         <p className="font-semibold">
//           Latest AI Tools, News & Jobs updated everyday
//         </p>
//       </div>
//       <nav className="flex items-center justify-between flex-wrap bg-[#f8f7f7] font-bold shadow-md  pl-10 pr-10">
//         <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
//           <img src={logo} className="w-100 mr-2 h-16" alt="Logo" />
//         </div>
//         <div className="block lg:hidden">
//           <button
//             onClick={() => setIsOpen(!isOpen)}
//             className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
//           >
//             <svg
//               className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
//               viewBox="0 0 20 20"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
//             </svg>
//             <svg
//               className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
//               viewBox="0 0 20 20"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
//             </svg>
//           </button>
//         </div>
//         <div
//           className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
//             isOpen ? "block" : "hidden"
//           }`}>
//           <div className="gap-9 flex text-sm lg:flex-grow lg:flex-row sm:flex-col">
//                <NavLink
//                   exact
//                   to="/"
//                   className="hover:bg-green-100 rounded-md block p-2"
//                   activeClassName="bg-green-500"
//                 >
//                   🏠 Home
//                 </NavLink>
//                 <NavLink to="/news" className="hover:bg-green-100 rounded-md block p-2">
//                   📰 News
//                 </NavLink>
//                 <NavLink to="/jobs" className="hover:bg-green-100 rounded-md block p-2">
//                   💼 Jobs
//                 </NavLink>
//                 <NavLink
//                   to="/allAiTools"
//                   className="hover:bg-green-100 rounded-md block p-2"
//                 >
//                   🤖 AI Tools
//                 </NavLink>
//           </div>
//           <div>
//             <button className="inline-flex items-center bg-amber-500 border-0 py-2 px-4 text-white rounded-md">
//               Contact us
//             </button>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }
// export default Header;

// import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import logo from "../components/logo10.png";

// const Header = () => {
//   const [isNavOpen, setIsNavOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     handleResize();

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const toggleNav = () => {
//     setIsNavOpen(!isNavOpen);
//   };

//   const closeNav = () => {
//     setIsNavOpen(false);
//   };

//   return (
//     <div className="fixed top-0 left-0 w-full z-50">
//       <div className="text-center bg-[rgb(121,239,255)] h-[30px] pt-1">
//         <p className="font-semibold">
//           Latest AI Tools, News & Jobs updated everyday
//         </p>
//       </div>

//       <header className="bg-[#f8f7f7] py-4 font-bold shadow-md relative">
//         <div className="container mx-auto px-4 md:px-6 lg:px-8">
//           <div className="flex justify-between items-center">
//             {!isMobile && (
//               <div className="flex items-center h-6">
//                 <NavLink to="/">
//                   <img className="h-16" src={logo} alt="logo" />
//                 </NavLink>
//               </div>
//             )}
//             <div className="md:hidden fixed top-0 right-0 m-4">
//               <button
//                 onClick={toggleNav}
//                 className="p-2 mt-3 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//                 aria-controls="navbar-dropdown"
//                 aria-expanded={isNavOpen ? "true" : "false"}
//               >
//                 <span className="sr-only">Toggle navigation</span>
//                 <svg
//                   className="w-6 h-6"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   {isNavOpen ? (
//                     <path
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M6 18L18 6M6 6l12 12"
//                     />
//                   ) : (
//                     <path
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M4 6h16M4 12h16M4 18h16"
//                     />
//                   )}
//                 </svg>
//               </button>
//             </div>
//             <nav
//               className={`middle-navbar flex flex-col items-start md:hidden ${
//                 isNavOpen ? "block" : "hidden"
//               }`}
//             >
//               <button onClick={closeNav}>
//                 <NavLink
//                   exact
//                   to="/"
//                   className="hover:underline block p-2"
//                   activeClassName="text-green-500"
//                 >
//                   🏠 Home
//                 </NavLink>
//               </button>
//               <button onClick={closeNav}>
//                 <NavLink to="/News" className="hover:underline block p-2">
//                   📰 News
//                 </NavLink>
//               </button>
//               <button onClick={closeNav}>
//                 <NavLink to="/Jobs" className="hover:underline block p-2">
//                   💼 Jobs
//                 </NavLink>
//               </button>
//               <button onClick={closeNav}>
//                 <NavLink to="/AllAiTools" className="hover:underline block p-2">
//                   🤖 AI Tools
//                 </NavLink>
//               </button>
//               <button
//                 onClick={closeNav}
//                 className="p-2 rounded-lg hover:underline"
//               >
//                 ✉️ Contact us
//               </button>
//             </nav>
//             <nav
//               className={`middle-navbar hidden md:flex items-center space-x-6 lg:space-x-10 m-auto ${
//                 isMobile && isNavOpen ? "block" : "hidden"
//               }`}
//             >
//               <button>
//                 <NavLink
//                   exact
//                   to="/"
//                   className="hover:underline"
//                   activeClassName="text-green-500"
//                 >
//                   🏠 Home
//                 </NavLink>
//               </button>
//               <button>
//                 <NavLink to="/News" className="hover:underline">
//                   📰 News
//                 </NavLink>
//               </button>
//               <button>
//                 <NavLink to="/Jobs" className="hover:underline">
//                   💼 Jobs
//                 </NavLink>
//               </button>
//               <button>
//                 <NavLink to="/AllAiTools" className="hover:underline">
//                   🤖 AI Tools
//                 </NavLink>
//               </button>
//               <button className="px-4 rounded-lg hover:underline">
//                 ✉️ Contact us
//               </button>
//             </nav>
//             <div></div>
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// };

// export default Header;
