
export const CardContent = [
    {
        image:"./images/hanooman.png" || " hello",
        name:"Hanooman AI",
        tag:"Chat Assistant",
        description:"Hanooman AI is a free multilingual GenAI platform supporting 98 global and 12 Indian languages, with future paid subscriptions planned.",
        MyLink:"https://www.hanooman.ai/",
        id:0
    },
    {
        image:"./images/chatgpt.png" || " hello",
        name:"ChatGPT",
        tag:"Chat Assistant",
        description:"ChatGPT improves language models for conversations, enhancing the quality and relevance of your chatbot's replies.",
        MyLink:"https://chat.openai.com/",
        id:1
    },
    {
        image:"./images/gemini.png",
        name:"Gemini",
        tag:"Chat Assistant",
        description:"Google gemini supercharge your creativity and productivity- Chat to start writing, planning, learning, research, study and more.",
        MyLink:"https://gemini.google.com/",
        id:2
    },
    {
        image:"./images/dalle.png",
        name:"DALL-E 2",
        tag:"images",
        description:"A revolutionary AI system that can generate realistic images and art from a simple text description.",
        MyLink:"https://openai.com/dall-e-2",
        id:3
    },
    {
        image:"./images/jasper.png",
        name:"Jasper AI",
        tag:"Writing assistant",
        description:"An AI writing assistant that excels in creating different kinds of marketing copy, blog posts, and even creative text formats like poems and code.",
        MyLink:"https://www.jasper.ai/",
        id:4
    },
    {
        image:"./images/midjourney.png",
        name:"Midjourney",
        tag:"text to images",
        description:"Midjourney is an AI art generator that transforms your text descriptions into stunning and unique images.",
        MyLink:"https://www.midjourney.com/home",
        id:5
    },
    {
        image:"./images/Synthesia.png",
        name:"Synthesia",
        tag:"text to videos",
        description:"Synthesia turns text into professional-looking videos with AI presenters. No cameras or filming required.",
        MyLink:"https://www.synthesia.io/",
        id:6
    },
    {
        image:"./images/tome.png",
        name:"Tome",
        tag:"Presentation",
        description:"AI-powered tool for generating comprehensive presentations, reports, and storytelling formats.",
        MyLink:"https://tome.app/",
        id:7
    },
    {
        image:"./images/namelix.png",
        name:"Namelix",
        tag:"Business",
        description:"AI-powered business name generator that creates short, brandable, and memorable names.",
        MyLink:"https://namelix.com/?utm_source=futurepedia&utm_medium=marketplace&utm_campaign=futurepedia#",
        id:8
    },
    {
        image:"./images/tome01.png",
        name:"Tome",
        tag:"Presentations",
        description:"Tome is a AI Powered tool that helps you create presentations and other forms of narrative content quickly and easily.",
        MyLink:"https://tome.app/",
        id:9
    },
    {
        image:"./images/fliki.png",
        name:"Fliki",
        tag:"video, audio",
        description:"Convert text to video and audio content, including professional-grade voiceovers, Fliki has over 850 AI voices that can replicate human speech patterns and emotions",
        MyLink:"https://fliki.ai/",
        id:10
    },
    {
        image:"./images/Humata AI.png",
        name:"Humata AI",
        tag:"Legal, Education, Research",
        description:"AI tool that helps users understand complex documents by summarizing text-based files, such as PDFs.",
        MyLink:"https://www.humata.ai/",
        id:11
    },
    {
        image:"./images/looka.png",
        name:"Looka",
        tag:"Logo design, website",
        description:"Looka is an AI-powered graphic design platform that helps businesses create logos, branding kits, and other marketing materials.",
        MyLink:"https://looka.com/",
        id:12
    },
    {
        image:"./images/Codeium.png",
        name:"Codeium",
        tag:"Coding Assistant",
        description:"AI-Powered Autocomplete. Autocomplete faster than thought. Codeium's generative code can save you time and help you ship products faster.",
        MyLink:"https://codeium.com/",
        id:13
    },
    {
        image:"./images/perplexity.png",
        name:"Perplexity",
        tag:"Search engine",
        description:"Perplexity.ai is a chatbot-style search engine that uses AI to gather information from the web and provide answers in natural language.",
        MyLink:"https://www.perplexity.ai/",
        id:14
    },
    {
        image:"./images/10web.png",
        name:"10web",
        tag:"Website",
        description:"Create a website using AI Website Builder, host it on 10 Web Hosting, and optimize it with PageSpeed Booster.",
        MyLink:"https://10web.io/",
        id:15
    },
    {
        image:"./images/rytr.png",
        name:"Rytr",
        tag:"Writting",
        description:"Rytr is an AI writing tool that can generate content for a variety of types, including blog articles, social media posts, and essays.",
        MyLink:"https://rytr.me/",
        id:16
    },
    {
        image:"./images/chatbase.png",
        name:"Chatbase",
        tag:"Chatbot",
        description:"Chatbase is a conversational AI platform that lets users build, train, and embed chatbots powered by ChatGPT on their website.",
        MyLink:"https://www.chatbase.co/",
        id:17
    },
    {
        image:"./images/sitegpt.png",
        name:"SiteGPT",
        tag:"Chatbot",
        description:"SiteGPT is an AI chatbot platform that creates a personalized chatbot trained on a user's own website content.",
        MyLink:"https://sitegpt.ai/",
        id:18
    },
    {
        image:"./images/chatsonic.png",
        name:"Chatsonic",
        tag:"Chatbot",
        description:"Chatsonic is an AI chatbot that helps with writing tasks using generative AI. It's built on the GPT-4 model and uses NLP and ML technology.",
        MyLink:"https://writesonic.com/chat",
        id:19
    },
    {
        image:"./images/adcreative01.png",
        name:"Adcreative",
        tag:"Marketing",
        description:"Ad creative is a Paris-based AI tool that helps businesses create ad creatives for social, search, and display campaigns.",
        MyLink:"https://www.adcreative.ai/",
        id:20
    },
    {
        image:"./images/simlified.png",
        name:"Simplified AI",
        tag:"Marketing",
        description:"Simplified AI help with content creation. It's designed for small teams who are short on time and resources, and offers tools for text, imagery, and video.",
        MyLink:"https://simplified.com/",
        id:21
    },
    {
        image:"./images/decktopus.png",
        name:"Decktopus",
        tag:"Presentations",
        description:"AI-powered presentation maker generates content, designs slides, and provides scripting guidance by analyzing the presentation's topic, audience, and goals.",
        MyLink:"https://www.decktopus.com/",
        id:22
    },
    {
        image:"./images/photoroom.png",
        name:"Photoroom",
        tag:"images",
        description:"Revolutionize your photos with AI-powered background removal and enhancement, making every picture stand out effortlessly",
        MyLink:"https://www.photoroom.com/",
        id:23
    },
    {
        image:"./images/snapby.png",
        name:"Snapby",
        tag:"images",
        description:"Explore the world of AI generated images, a game-changer in the realm of stock images. Discover how AI images are transforming visual content creation.",
        MyLink:"https://snapby.ai/",
        id:24
    },
    {
        image:"./images/taskade.png",
        name:"Taskade",
        tag:"Productivity",
        description:"An AI-powered team organizer to simplify the way your team works. Taskade helps you distribute work among team members to get work done faster and smarter.",
        MyLink:"https://www.taskade.com/",
        id:25
    },
    {
        image:"./images/videotok.png",
        name:"Videotok",
        tag:"Text to video",
        description:"Videotok is an AI-powered platform that creates videos from text inputs, including images and real images.",
        MyLink:"https://www.videotok.app/",
        id:26
    },
    {
        image:"./images/Cartoonifier.png",
        name:"Cartoonifier",
        tag:"Cartoon images",
        description:"Cartoonifier is an AI tool that uses a photo to create a cartoon character. It can turn photos into whimsical cartoons.",
        MyLink:"https://cartoonifier.com/",
        id:27
    },
    {
        image:"./images/musicfy.png",
        name:"Musicfy",
        tag:"music",
        description:"An AI-powered tool that lets you create your own covers of popular songs in just seconds.",
        MyLink:"hhttps://musicfy.lol/",
        id:28
    },
    {
        image:"./images/bardeen.png",
        name:"Bardeen",
        tag:"Assistant",
        description:"Turns your phone into a personal AI assistant that can schedule meetings, take notes, and provide updates via messaging.",
        MyLink:"https://www.bardeen.ai/",
        id:29
    },
    {
        image:"./images/chatpdf.png",
        name:"Chat-PDF",
        tag:"Assistant",
        description:"ChatPDF allows you to chat with any PDF document, making document navigation as easy as having a conversation.",
        MyLink:"https://www.chatpdf.com/",
        id:30
    },
    {
        image:"./images/ailawyer.png",
        name:"Ai Lawyer",
        tag:"Assistant",
        description:"AI Lawyer provides expert legal advice, document creation tools, and a user-friendly interface for understanding legal obligations. Get personal AI-lawyer expertise at your fingertips with AI Lawyer.",
        MyLink:"https://ailawyer.pro/",
        id:30
    },
    {
        image:"./images/uizard.png",
        name:"UI Zard",
        tag:"Design",
        description:"Uizard is an AI-powered design tool that lets you create stunning web and mobile apps in minutes, even without any design experience.",
        MyLink:"https://uizard.io/",
        id:31
    },
    {
        image:"./images/elevenlabs.png",
        name:"Elevenlabs",
        tag:"Voice",
        description:"Eleven Labs instantly generates natural AI voices from text in any language, perfect for video creators, developers, and businesses.",
        MyLink:"https://elevenlabs.io/",
        id:32
    },
    {
        image:"./images/decktopus.png",
        name:"Decktopus",
        tag:"Presentation",
        description:"World's #1 AI-powered presentation generator, streamlining the creation of engaging and visually appealing presentations.",
        MyLink:"https://www.decktopus.com/",
        id:33
    },
    
    {
        image:"./images/marketingblocks.png",
        name:"Marketing Assistant",
        tag:"Marketing",
        description:"MarketingBlocks is an all-in-one AI marketing suite that creates landing pages, promo videos, ads, marketing copy, graphics, email swipes, blog posts, articles, art and more in minutes.",
        MyLink:"https://hey.marketingblocks.ai/",
        id:34
    },
    
    {
        image:"./images/logoai.png",
        name:"Logo AI",
        tag:"Logo design",
        description:"LogoAI is an AI engine that creates unique logo designs and branding templates based on design best practices for business cards, posters, flyers, and social media.",
        MyLink:"https://www.logoai.com/",
        id:35
    },

    {
        image:"./images/pictory.png",
        name:"Pictory",
        tag:"Video",
        description:"Pictory automatically creates short, highly sharable branded videos from long-form content, enhancing your video marketing efforts.",
        MyLink:"https://pictory.ai/",
        id:36
    },
    {
        image:"./images/meetgeek.png",
        name:"Meetgeek",
        tag:"Productivity",
        description:"MeetGeek is an AI-powered meeting automation platform that can automatically record, transcribe, summarize, and share meeting insights.",
        MyLink:"https://meetgeek.ai/",
        id:37
    },
    {
        image:"./images/hoopsAi.png",
        name:"Meetgeek",
        tag:"Investment",
        description:"hoopsAI is a web application that uses AI to provide real-time trading insights and analysis to retail investors.",
        MyLink:"https://www.hoopsai.com/",
        id:38
    },
    {
        image:"./images/cody.png",
        name:"Cody",
        tag:"Assistant",
        description:"Cody is a free, open-source AI coding assistant that can write and fix code, provide AI-generated autocomplete, and answer your coding questions.",
        MyLink:"https://meetcody.ai/",
        id:39
    },
    {
        image:"./images/ideamap.png",
        name:"Ideamap",
        tag:"Assistant",
        description:"Ideamap is where ideas and AI come together. Empower your team to brainstorm better and faster.",
        MyLink:"https://ideamap.ai/",
        id:40
    },
    {
        image:"./images/beatopia.png",
        name:"Beatopia",
        tag:"Audio",
        description:"AI SuitUp generates high-quality AI headshots that take your profile to the next level.",
        MyLink:"https://www.beatopia.com/",
        id:41
    },
    {
        image:"./images/profilepicture.png",
        name:"Profile Picture",
        tag:"Images",
        description:"Profile Picture AI utilizes artificial intelligence to generate a pfps of you that looks perfect and captures who you are. You can be anything or anyone!",
        MyLink:"https://www.profilepicture.ai/",
        id:42
    },
    {
        image:"./images/artsmart.png",
        name:"Art Smart",
        tag:"Images",
        description:"Artsmart.ai leverages the power of AI trained on the worlds best artists to generate incredible art, stock images, avatars, and portraits.",
        MyLink:"https://artsmart.ai/",
        id:43
    },
    {
        image:"./images/linkdeinleads.png",
        name:"Linked-leads",
        tag:"Linkdein Business",
        description:"LinkedLeads maximizes your LinkedIn network to generate an unlimited number of leads for your business.",
        MyLink:"https://linkedleads.app/",
        id:44
    },
    {
        image:"./images/friz.png",
        name:"Friz",
        tag:"Social Media",
        description:"Friz automates social media management using AI, tailored for direct-to-consumer brands.",
        MyLink:"https://www.usefriz.com/",
        id:45
    },
    {
        image:"./images/picasso.png",
        name:"AI Picasso",
        tag:"Social Media",
        description:"AI dance transforms static pictures into full-body dance animations with AI technology.",
        MyLink:"https://aipicasso.app/",
        id:46
    },
    {
        image:"./images/docgpt.png",
        name:"Doc-gpt",
        tag:"Productivity",
        description:"Boost your efficiency 10x with one-click email writing, document, SEO article creation, GPT for Sheets. All seamlessly integrated with AI.",
        MyLink:"https://docgpt.ai/",
        id:47
    },
    {
        image:"./images/codemonk.png",
        name:"Code monk",
        tag:"Coding",
        description:"CodeMonk is a platform that helps developers practice and understand algorithmic programming concepts. The series covers topics like data structures, algorithms, and mathematics.",
        MyLink:"https://www.codemonk.ai/",
        id:48
    },
    {
        image:"./images/voiceswap.png",
        name:"Voice Swap",
        tag:"Audio",
        description:"Voice Swap lets you change your singing voice with the power of AI. Easily transform your vocals to match the style of our chart-topping singers.",
        MyLink:"https://gpte.ai/",
        id:49
    },
    {
        image:"./images/apihunt.png",
        name:"API Hunt",
        tag:"Coding",
        description:"Api Hunt offers access to a curated library of over 500 technology APIs for diverse applications.",
        MyLink:"https://apihunt.io/",
        id:50
    },
    {
        image:"./images/aifaceswap.png",
        name:"AI Face Swap",
        tag:"Images",
        description:"Ai Face Swap Sticker enables you to create personalized meme stickers using your own images for messaging.",
        MyLink:"https://appintro.io/stico-ai-face-swap-sticker",
        id:51
    },
    {
        image:"./images/wifimouse.png",
        name:"Wifi Mouse",
        tag:"Productivity",
        description:"WiFi Mouse allows you to wirelessly control your computer for enhanced convenience and flexibility.",
        MyLink:"https://wifimouse.necta.us/",
        id:52
    },
    {
        image:"./images/sharly.png",
        name:"Sharly AI",
        tag:"Assistant",
        description:"Sharly AI facilitates interactive conversations with any document or PDF, enhancing document engagement and comprehension.",
        MyLink:"https://sharly.ai/",
        id:53
    },
    {
        image:"./images/DrGPT.png",
        name:"Dr. GPT",
        tag:"Doctor Assistant",
        description:"Dr. GPT is your AI-powered health assistant, providing personalized medical guidance and support.",
        MyLink:"https://chat.openai.com/g/g-bJxUTxs6F-dr-gpt",
        id:54
    },
    {
        image:"./images/lovelocket.png",
        name:"Love Locket",
        tag:"Dating",
        description:"Locket utilizes AI to revolutionize the dating scene by helping users discover their perfect match.",
        MyLink:"https://www.lovelocket.ai/",
        id:55
    },
    {
        image:"./images/Reusmenalyzer.png",
        name:"Resume",
        tag:"Resume Builder",
        description:"Resumenalyzer streamlines your job search by optimizing the resume review process.",
        MyLink:"https://www.resumenalyzer.com/",
        id:56
    },
    {
        image:"./images/1min.png",
        name:"1min AI",
        tag:"Code Assistant",
        description:"1min.AI offers a comprehensive AI solution with a suite of diverse AI models catered to automate and enhance various tasks.",
        MyLink:"https://1min.ai/",
        id:57
    },
    {
        image:"./images/explorify.png",
        name:"Explorify",
        tag:"Travel Assistant",
        description:"AI Travel: A hybrid platform combining elements from Maps and Viator, enhanced with OpenAI's capabilities for innovative travel experiences.",
        MyLink:"https://www.tryexplorify.com/",
        id:58
    },
    {
        image:"./images/textreader.png",
        name:"Text Reader",
        tag:"Text to Audio",
        description:"Text Reader AI offers a free text-to-speech conversion featuring lifelike AI-generated voices.",
        MyLink:"https://textreader.ai/",
        id:59
    },
    {
        image:"./images/giftnotewriter.png",
        name:"Gift Note Writer",
        tag:"Writting Assistant",
        description:"Gift Note Writer crafts personalized gift notes using AI technology for a unique touch to presents.",
        MyLink:"https://chromewebstore.google.com/detail/akpjpdocjcnmapjgdpmeiehoklggagnl",
        id:60
    },
    {
        image:"./images/m1-projectai.png",
        name:"M1 Project",
        tag:"Bussiness",
        description:"M1-project: Marketing Strategy Generator leverages AI to develop effective marketing strategies for startups.",
        MyLink:"https://m1-project.com/",
        id:61
    },
    {
        image:"./images/devika.png",
        name:"Devika",
        tag:"AI Software Engineer",
        description:"Devika is an AI software that uses machine learning to analyze user prompts and generate text, content, and graphics accordingly.",
        MyLink:"https://github.com/stitionai/devika?tab=readme-ov-file#readme",
        id:62
    },
    {
        image:"./images/merlin.png",
        name:"Merlin AI",
        tag:"AI Assistant Chatbots",
        description:"Merlin AI is a self-learning generative AI engine that uses Artificial General Intelligence (AGI) to create written content for push marketing campaign.",
        MyLink:"https://www.getmerlin.in/",
        id:63
    },
    {
        image:"./images/refresherai.png",
        name:"TimePlay",
        tag:"Education",
        description:"Refresher AI helps you retain and practice knowledge from your reading material effectively.",
        MyLink:"https://refresherai.com/",
        id:64
    },
    {
        image:"./images/sread.png",
        name:"SRead",
        tag:"Education",
        description:"AI-powered assistant that enhances reading efficiency and comprehension through intelligent guidance.",
        MyLink:"https://sread.ai/",
        id:65
    },
    {
        image:"./images/munch.png",
        name:"Munch",
        tag:"Social media",
        description:"AI-powered platform that repurposes long-form content into short clips for social media platforms like YouTube, Instagram, TikTok, and LinkedIn.",
        MyLink:"https://www.getmunch.com/",
        id:66
    },
    {
        image:"./images/meals.png",
        name:"Meals",
        tag:"Lifestyle",
        description:"Meals.chat enables you to monitor your dietary intake by submitting photos of your meals to track calories and macronutrients.",
        MyLink:"https://meals.chat/",
        id:67
    },
    {
        image:"./images/drchange.png",
        name:"Dr Change",
        tag:"Lifestyle",
        description:"Dr. Change is an AI-powered habit coaching platform that guides users in developing positive routines.",
        MyLink:"https://drchange.co/",
        id:68
    },
    {
        image:"./images/adintelli.png",
        name:"Addintelli",
        tag:"Business",
        description:"AdIntell.ai enables monetization of your GPTs through targeted advertising revenue streams.",
        MyLink:"https://adintelli.ai/",
        id:69
    },
    {
        image:"./images/idealcustomers.png",
        name:"Ideal Customers AI",
        tag:"Business",
        description:"IdealCustomers-AI: Your Audience Finder enables precise targeting to connect with the ideal market for your product.   ",
        MyLink:"https://idealcustomers-ai.com/",
        id:70
    },
    {
        image:"./images/leprechaun.png",
        name:"Leprechaun AI",
        tag:"Gaming",
        description:"Leprechaun AI enables the creation and engagement with AI-powered role-playing adventures.",
        MyLink:"https://leprechaun.ai/",
        id:71
    },
    {
        image:"./images/professorai.png",
        name:"ProfessorAI",
        tag:"Educational",
        description:"ProfessorAI is the personalized AI tutor for students in AP Courses.",
        MyLink:"https://app.professorai.co/explore?agent=",
        id:72
    },
    {
        image:"./images/ai or not.png",
        name:"AI or NOT",
        tag:"AI Detector",
        description:"Detect AI-generated images & audio for your business - and yourself - with AI or Not.",
        MyLink:"https://www.aiornot.com/",
        id:73
    },
    {
        image:"./images/qrevai.png",
        name:"QRev AI",
        tag:"Business",
        description:"Offers Open Source AI agents for companies to scale their Sales organization infinitely.",
        MyLink:"https://qrev.ai/",
        id:74
    },
    {
        image:"./images/krutrim.png",
        name:"Krutrim AI",
        tag:"Chat Assistant",
        description:"Krutrim AI, developed by Ola, uses NLP and ML to generate human-like text, understand Indian languages.",
        MyLink:"https://chat.olakrutrim.com/home",
        id:75
    },
    {
        image:"./images/gamma.png",
        name:"Gamma",
        tag:"Presentation",
        description:"AI Powered Beautiful presentations, documents, and websites. No design or coding skills required.",
        MyLink:"https://gamma.app/",
        id:76
    },
    {
        image:"./images/doctranlate.png",
        name:"Doctranslate.io",
        tag:"Translation",
        description:"AI-powered translation service that supports over 30 languages and can translate documents into any language.",
        MyLink:"https://doctranslate.io/translate",
        id:77
    },   
    {
        image:"./images/reply.webp",
        name:"Reply",
        tag:"BUSINESS",
        description:"Generate leads, convert website traffic to meetings, or hire AI SDR Agents within Reply using multichannel sequences and unlimited mailboxes.",
        MyLink:"https://reply.io/",
        id:78
    },   
    {
        image:"./images/viggle.webp",
        name:"Viggle AI",
        tag:"Video",
        description:"Viggle AI is a free AI video generator that uses 3D modeling technology to create realistic animations that incorporate real-world physics.",
        MyLink:"https://viggle.ai/",
        id:79
    },   
    {
        image:"./images/coopilot.png",
        name:"GitHub Copilot",
        tag:"Code",
        description:"GitHub Copilot is an AI pair programmer that offers autocomplete-style suggestions as you code.",
        MyLink:"https://github.com/features/copilot",
        id:80
    },
       
    {
        image:"",
        name:"",
        tag:"",
        description:"",
        MyLink:"",
        id:81
    },   
      
]
