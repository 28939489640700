import React from "react";

const News = () => {
  // Sample JSON data for news articles
  const newsData = [
    {
      title: "Hailo lands $120 million to keep battling Nvidia as most AI chip startups struggle",
      category: "News",
      link: "https://techcrunch.com/2024/04/02/hailo-ai-chip-startup-lands-120m-to-battle-nvidia/"
    },
    {
      title: "Sigma Raises $200 Million in Series D Funding",
      category: "News",
      link: "https://www.businesswire.com/news/home/20240516963033/en/Sigma-Raises-200-Million-in-Series-D-Funding"
    },
    {
      title: "Hugging Face commits $10 million in free shared GPUs for AI development",
      category: "News",
      link: "https://www.theverge.com/2024/5/16/24156755/hugging-face-celement-delangue-free-shared-gpus-ai"
    },
    {
      title: "OpenAI releases GPT-4o, a faster model that’s free",
      category: "News",
      link: "https://www.theverge.com/2024/5/13/24155493/openai-gpt-4o-launching-free-for-all-chatgpt-users"
    },
    {
      title: "Rad AI closes $50 million to empower physicians with AI ",
      category: "News",
      link: "https://techcrunch.com/2024/05/07/rad-ai-a-startup-that-helps-radiologists-save-time-on-report-generation-raises-50m-series-b-from-khosla-ventures/"
    },
    {
      title: "Paris-Based Holistic Raises $200 Million to Develop AI Model",
      category: "News",
      link: "https://www.pymnts.com/artificial-intelligence-2/2024/report-paris-based-holistic-raises-200-million-to-develop-ai-model/"
    },
    {
      title: "TikTok to label AI-generated content.",
      category: "News",
      link: "https://newsroom.tiktok.com/en-us/partnering-with-our-industry-to-advance-ai-transparency-and-literacy"
    },
    {
      title: "Apple’s new M4 chip is focused on AI.",
      category: "News",
      link: "https://www.theverge.com/2024/5/7/24148451/apple-m4-chip-ai-ipad-macbook"
    },
    {
      title: "OpenAI is developing a search engine called 'SearchGPT'.",
      category: "News",
      link: "https://www.bloomberg.com/news/articles/2024-05-07/openai-is-readying-an-ai-search-product-to-rival-google-perplexity"
    },
    {
      title: "The New York Times has initiated a lawsuit against OpenAI and Microsoft.",
      category: "News",
      link: "https://www.reuters.com/legal/us-newspapers-sue-openai-copyright-infringement-over-ai-training-2024-04-30/"
    },
    {
      title: "Microsoft invested in OpenAI because of Google’s advancements in AI.",
      category: "News",
      link: "https://www.theverge.com/2024/5/1/24146302/microsoft-openai-investment-google-worries-internal-emails"
    },
    {
      title: "Memory is now available to all ChatGPT Plus users.",
      category: "News",
      link: "https://twitter.com/OpenAI/status/1784992796669096181"
    },
    {
      title: "China released their own version of OpenAI’s Sora.",
      category: "News",
      link: "https://twitter.com/AiBreakfast/status/1784187696518180963"
    },
    {
      title: "Apple has quietly launched a major AI lab in Zurich.",
      category: "News",
      link: "https://tech.hindustantimes.com/tv/news/apple-hires-36-ex-google-experts-to-set-up-its-secret-ai-lab-in-zurich-key-things-to-know-71714562634476.html"
    },
    {
      title: "Intel Takes Aim at Nvidia with New Gaudi 3 AI Chip.",
      category: "News",
      link: "https://www.forbes.com/sites/marcochiappetta/2024/04/09/intel-unveils-efficient-gaudi-3-ai-accelerator-and-new-xeons-at-vision/?shintel-guns-for-nvidia-with-gaudi-3-ai-chip&sh=387b52661709"
    },
    {
      title: "AI Learns to Sway Humans by Watching a Cooperative Cooking Game.",
      category: "News",
      link: "https://www.sciencenews.org/article/ai-learning-cooperative-cooking-game-artifical-intelligence-overcooked?utm_source=www.joinhorizon.ai&utm_medium=newsletter&utm_campaign=intel-guns-for-nvidia-with-gaudi-3-ai-chip"
    },
   
  ];

  return (
    <div className="mt-24 lg:mt-28">
      <div className="flex flex-col justify-center items-center mt-10 text-center leading-10">
        <div className="sm:text-5xl font-semibold lg:text-6xl leading-10 lg:mt-8 sm:mt-14">
          Latest <span className="text-green-600">&nbsp;AI News&nbsp;</span> and updates
        </div>
        <p className="mt-4 font-mono text-3xl leading-10">Latest AI news here</p>
      </div>
      <div className="flex-col lg:flex-row justify-center items-center lg:space-x-10 mt-10">
        {newsData.map((article, index) => (
          <div key={index} className="flex items-center bg-black flex-wrap p-6 m-7 text-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ">
            <div className="w-full">
              <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">{article.title}</h5>
              <p className="mb-3 font-normal dark:text-gray-400">📰 {article.category}</p>
            </div>
            <div>
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-green-500 hover:bg-green-800 rounded-lg"
              >
                Read More
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;



// main code-
// import React from "react";

// const News = () => {
//   return (
//     <div className="mt-24 lg:mt-28">
//       <div className="flex flex-col justify-center items-center mt-10 text-center leading-10">
//         <div className="text-3xl font-semibold lg:text-6xl leading-10">
//           Latest AI news and updates
//         </div>
//         <p className="mt-2 font-mono leading-10">Latest AI news here</p>
//       </div>
//       <div>
//       <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-10 mt-10 p-5">
//         <div className="flex items-center flex-wrap p-6 m-7 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:w-1/2">
//           <div className="w-full">
//             <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
//             Amazon and Anthropic deepen their shared commitment to advancing generative AI.
//             </h5>
//             <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
//               📰 News
//             </p>
//           </div>
//           <div>
//             <a
//               href="https://www.aboutamazon.com/news/company-news/amazon-anthropic-ai-investment?utm_source=www.neatprompts.com&utm_medium=newsletter&utm_campaign=ai-studio-for-marketers"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Read More...
//               <svg
//                 className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
//                 aria-hidden="true"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 14 10"
//               >
//                 <path
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M1 5h12m0 0L9 1m4 4L9 9"
//                 />
//               </svg>
//             </a>
//           </div>
//         </div>

//         <div className="flex items-center flex-wrap p-6 m-7 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:w-1/2">
//           <div className="w-full">
//             <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
//             Budgeting app Copilot is booming now that Mint is dead, leading to $6M Series A.
//             </h5>
//             <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
//               📰 News
//             </p>
//           </div>
//           <div>
//             <a
//               href="https://techcrunch.com/2024/03/21/budgeting-app-copilot-mint-6m-series-a/?utm_source=www.neatprompts.com&utm_medium=newsletter&utm_campaign=ai-studio-for-marketers"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Read More...
//               <svg
//                 className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
//                 aria-hidden="true"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 14 10"
//               >
//                 <path
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M1 5h12m0 0L9 1m4 4L9 9"
//                 />
//               </svg>
//             </a>
//           </div>
//         </div>
        
//       </div>
//       </div>
      
//     </div>
//   );
// };

// export default News;
