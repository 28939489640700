import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebaseAppConfig from "../util/firebase-config";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import sImg from "../components/signup.jpg";

const auth = getAuth(firebaseAppConfig);

const Signup = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  const [formValue, setFormValue] = useState({
    fullname: "",
    email: "",
    password: "",
  });

  const signup = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      await createUserWithEmailAndPassword(
        auth,
        formValue.email,
        formValue.password
      );
      navigate("/");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoader(false);
    }
  };

  const handleOnChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.value;
    setFormValue({
      ...formValue,
      [name]: value,
    });
    setError(null);
  };

  return (
    <div className="animate__animated animate__fadeIn mt-4 sm:mt-10 container mx-auto">
      {/* <div className="w-full h-24 sm:h-48 md:h-72 lg:h-96 xl:h-540">
        <img src={sImg} className="w-full h-full object-cover" alt="Signup" />
      </div> */}
      <div className="flex flex-col sm:p-8 md:p-12 lg:p-16 xl:p-20 max-w-2xl mx-auto mt-10 sm:mt-24 bg-[#7fcdcd] rounded-3xl h-full">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold">New Users</h1>
        <p className="text-base sm:text-lg mt-1 text-white">
          Create your account for new updates
        </p>
        
        <form className="mt-4 space-y-4" onSubmit={signup}>
          <div className="flex flex-col">
            <label className="font-semibold text-base sm:text-lg mb-1">Fullname</label>
            <input
              onChange={handleOnChange}
              required
              name="fullname"
              placeholder="Jack dev"
              className="p-2 sm:p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold text-base sm:text-lg mb-1">Email id</label>
            <input
              onChange={handleOnChange}
              required
              type="email"
              name="email"
              placeholder="example@mail.com"
              className="p-2 sm:p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="flex flex-col relative">
            <label className="font-semibold text-base sm:text-lg mb-1">Password</label>
            <input
              onChange={handleOnChange}
              required
              type={passwordType}
              name="password"
              placeholder="********"
              className="p-2 sm:p-3 border border-gray-300 rounded"
            />
            <button
              onClick={() =>
                setPasswordType(
                  passwordType === "password" ? "text" : "password"
                )
              }
              type="button"
              className="absolute top-8 sm:top-11 right-4 w-6 h-6 sm:w-8 sm:h-8 rounded-full hover:bg-blue-200 hover:text-blue-600"
            >
              {passwordType === "password" ? (
                <i className="ri-eye-line"></i>
              ) : (
                <i className="ri-eye-off-line"></i>
              )}
            </button>
          </div>
          {error && (
            <div className="flex justify-between items-center bg-rose-600 p-1 rounded shadow text-white font-thin animate__animated animate__pulse">
              <p>{error}</p>
              <button onClick={() => setError(null)}>
                <i className="ri-close-line"></i>
              </button>
            </div>
          )}
          {loader ? (
            <h1 className="text-base sm:text-lg font-semibold text-gray-600">Loading...</h1>
          ) : (
            <button className="py-2 px-4 sm:py-3 sm:px-8 rounded bg-blue-600 text-white font-semibold hover:bg-rose-600">
              Signup
            </button>
          )}
        </form>
        <div className="mt-2">
          Already have an account?{" "}
          <Link to="/login" className="text-blue-600 font-semibold">
            Login here
          </Link>
        </div>
      </div>
    </div>
  );
  
}  

export default Signup;
