import React, { useState } from "react";
import { CardContent } from "../content/CardContent";
import Searchform from "./Searchform";

const Card = () => {
  const [displayedCards, setDisplayedCards] = useState(8);
  const [data, setData] = useState(""); 

  const handleShowMore = () => {
    setDisplayedCards((prevDisplayedCards) => prevDisplayedCards + 4);
  };

  // Filter the CardContent based on the search criteria
  const filteredData = CardContent.filter((da) =>
    da.tag.trim().toLowerCase().includes(data.trim().toLowerCase()) ||
    da.name.trim().toLowerCase().includes(data.trim().toLowerCase())
  );

  return (
    <div className="">
      <div>
        <Searchform setData={setData} />
      </div>
      {filteredData.length === 0 ? (
        <div className="text-center mt-4 font-extrabold text-2xl text-red-500 ">Not Available <br /> <span className="font-serif text-slate-950" >You can search by AI Tool name or work of AI tools like ChatGPT, video, code, etc. </span> </div>
      
      )
       : (

        
        <div className="flex flex-wrap justify-center sm:mt-4 sm:ml-5 sm:mr-5 sm:text-3xl lg:text-sm sm:gap-9 lg:gap-0">
          {filteredData.slice(0, displayedCards).map((item) => (
            <div
              className="bg-white  w-full sm:w-full md:w-full lg:w-1/4 xl:w-1/5 m-2 lg:m-3 shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg hover:shadow-[rgba(0,_0,_0,_1)_0px_3px_8px] p-2 object-cover"
              key={item.id}
            >
              <img
                className="rounded-xl w-full object-contain sm:w-full"
                src={item.image}
                alt="img"
              />
              <div className="p-3">
                <h2 className="font-bold sm:text-5xl lg:text-xl">{item.name}</h2>
                <p className="font-normal text-green-500 sm:my-3 lg:my-0">→ {item.tag}</p>
                <div className="flex flex-row">
                  <p>{item.description}</p>
                </div>
                <div className="flex flex-row items-center justify-center mt-3 ">
                  <a
                    href={item.MyLink}
                    target="_blank" rel="noreferrer" className="flex justify-center p-2 bg-green-500 py-2 w-full rounded text-white font-semibold mt-4"
                  >
                    Visit- <i class="ri-external-link-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

{/* {data.map((item)=>(
  (
            <div
              className="bg-white  w-full sm:w-full md:w-full lg:w-1/4 xl:w-1/5 m-2 lg:m-3 shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg hover:shadow-[rgba(0,_0,_0,_1)_0px_3px_8px] p-2 object-cover"
              key={item.id}
            >
              <img
                className="rounded-xl w-full object-contain sm:w-full"
                src={item.image}
                alt="img"
              />
              <div className="p-3">
                <h2 className="font-bold sm:text-5xl lg:text-xl">{item.name}</h2>
                <p className="font-normal text-green-500 sm:my-3 lg:my-0">→ {item.tag}</p>
                <div className="flex flex-row">
                  <p>{item.description}</p>
                </div>
                <div className="flex flex-row items-center justify-center mt-3">
                  <a
                    href={item.MyLink}
                    target="_blank" rel="noreferrer" className="flex justify-center font-bold p-2 w-full rounded shadow-sm hover:shadow-slate-300 shadow-green-500 transition duration-300"
                  >
                    Visit 🔗
                  </a>
                </div>
              </div>
            </div>
          )
))} */}


      {displayedCards < filteredData.length && (
        <div className="flex justify-center mt-4">
          <button
            className="flex justify-center font-bold p-3 rounded shadow-[rgba(0,_0,_1,_1)_0px_3px_8px] hover:shadow-[rgba(0,_0,_0,_1)_0px_2px_10px] text-green-500"
            onClick={handleShowMore}
          >
            Show More AI Tools
          </button>
        </div>
      )}
      {/* Display default data */}
      
    </div>
  );
};

export default Card;



// import React, { useState } from "react";
// import { CardContent } from "../content/CardContent";
// import Searchform from "./Searchform";

// const Card = () => {
//   const [displayedCards, setDisplayedCards] = useState(8);
//   const [data, setData] = useState(""); 

//   const handleShowMore = () => {
//     setDisplayedCards((prevDisplayedCards) => prevDisplayedCards + 4);
//   };

//   // Filter the CardContent based on the search criteria
//   const filteredData = CardContent.filter((da) =>
//     da.tag.trim().toLowerCase().includes(data.trim().toLowerCase()) ||
//     da.name.trim().toLowerCase().includes(data.trim().toLowerCase())
//   );

//   // Define default data in case there are no matches
//   const defaultData = CardContent;

//   // If filteredData is empty, display defaultData
//   const displayData = filteredData.length > 0 ? filteredData : defaultData;

//   return (
//     <div>
//       <div>
//         <Searchform setData={setData} />
//       </div>
//       <div className="flex flex-wrap justify-center sm:mt-4 sm:ml-5 sm:mr-5 sm:text-3xl lg:text-sm sm:gap-9 lg:gap-0">
//         {displayData.slice(0, displayedCards).map((item) => (
//           <div
//             className="bg-white  w-full sm:w-full md:w-full lg:w-1/4 xl:w-1/5 m-2 lg:m-3 shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg hover:shadow-[rgba(0,_0,_0,_1)_0px_3px_8px] p-2 object-cover"
//             key={item.id}
//           >
//             <img
//               className="rounded-xl w-full object-contain sm:w-full"
//               src={item.image}
//               alt="img"
//             />
//             <div className="p-3">
//               <h2 className="font-bold sm:text-5xl lg:text-xl">{item.name}</h2>
//               <p className="font-normal text-green-500 sm:my-3 lg:my-0">→ {item.tag}</p>
//               <div className="flex flex-row">
//                 <p>{item.description}</p>
//               </div>
//               <div className="flex flex-row items-center justify-center mt-3">
//                 <a
//                   href={item.MyLink}
//                   target="_blank" rel="noreferrer" className="flex justify-center font-bold p-2 w-full rounded shadow-sm hover:shadow-slate-300 shadow-green-500 transition duration-300"
//                 >
//                   Visit 🔗
//                 </a>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       {displayedCards < displayData.length && (
//         <div className="flex justify-center mt-4">
//           <button
//             className="flex justify-center font-bold p-3 rounded shadow-[rgba(0,_0,_1,_1)_0px_3px_8px] hover:shadow-[rgba(0,_0,_0,_1)_0px_2px_10px] text-green-500"
//             onClick={handleShowMore}
//           >
//             Show More AI Tools
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Card;



// import React, { useState } from "react";
// import { CardContent } from "../content/CardContent";
// import Searchform from "./Searchform";

// const Card = () => {
//   const [displayedCards, setDisplayedCards] = useState(8);
//   const [data, setData] = useState(""); 

//   const handleShowMore = () => {
//     setDisplayedCards((prevDisplayedCards) => prevDisplayedCards + 4);
//   };

//   // const filteredData = CardContent.filter((da) =>
//   //   da.tag.toLowerCase().includes(data) || 
//   //  da.name.toLowerCase().includes(data) 
//   // );

//   const filteredData = CardContent.filter((da) =>
//     da.tag.trim().toLowerCase().includes(data.trim().toLowerCase()) ||
//     da.name.trim().toLowerCase().includes(data.trim().toLowerCase())
//   );  
  


//   return (
//     <div>
//       <div>
//         <Searchform setData={setData} />
//       </div>
//       <div className="flex flex-wrap justify-center sm:mt-4 sm:ml-5 sm:mr-5 sm:text-3xl lg:text-sm sm:gap-9 lg:gap-0">
//         {filteredData.slice(0, displayedCards).map((item) => (
//           <div
//             className="bg-white  w-full sm:w-full md:w-full lg:w-1/4 xl:w-1/5 m-2 lg:m-3 shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg hover:shadow-[rgba(0,_0,_0,_1)_0px_3px_8px] p-2 object-cover"
//             key={item.id}
//           >
//             <img
//               className="rounded-xl w-full object-contain sm:w-full"
//               src={item.image}
//               alt="img"
//             />
//             <div className="p-3">
//               <h2 className="font-bold sm:text-5xl lg:text-xl">{item.name}</h2>
//               <p className="font-normal text-green-500 sm:my-3 lg:my-0">→ {item.tag}</p>
//               <div className="flex flex-row">
//                 <p>{item.description}</p>
//               </div>
//               <div className="flex flex-row items-center justify-center mt-3">
//                 <a
//                   href={item.MyLink}
//                   target="_blank" rel="noreferrer" className="flex justify-center font-bold p-2 w-full rounded shadow-sm hover:shadow-slate-300 shadow-green-500 transition duration-300"
//                 >
//                   Visit 🔗
//                 </a>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       {displayedCards < filteredData.length && (
//         <div className="flex justify-center mt-4">
//           <button
//             className="flex justify-center font-bold p-3 rounded shadow-[rgba(0,_0,_1,_1)_0px_3px_8px] hover:shadow-[rgba(0,_0,_0,_1)_0px_2px_10px] text-green-500"
//             onClick={handleShowMore}
//           >
//             Show More AI Tools
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Card;
