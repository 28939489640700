
import React from "react";
import { CardContent } from "../content/CardContent";

const AllAiTools = ({ data }) => {
  return (
    <div >
      <div className="flex justify-center sm:mt-48 lg:mt-32 font-bold sm:text-7xl lg:text-6xl leading-10">
        All <span className="text-green-600 animate__bounce">&nbsp;AI Tools&nbsp;</span> of the World.
      </div>
      <p className="flex justify-center sm:mt-10 sm:mb-14 lg:mb-1 font-mono leading-10 sm:text-4xl lg:text-2xl">Find AI Tools for your work</p>
      <div className="flex flex-wrap justify-center sm:mt-4 sm:ml-5 sm:mr-5 sm:text-3xl lg:text-sm">
        {CardContent.map((item) => (
          <div
            className="bg-white w-full sm:w-full md:w-full lg:w-1/4 xl:w-1/5 m-2 lg:m-3 shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg hover:shadow-[rgba(0,_0,_0,_1)_0px_3px_8px] p-2 object-cover"
            key={item.id}
          >
            <img
              className="rounded-xl w-full object-contain sm:w-full "
              src={item.image}
              alt="img"
            />
            <div className="p-3">
             <div className="">
             <h2 className="font-bold  sm:text-5xl lg:text-xl">{item.name}</h2>
              <p className="font-normal text-green-500 sm:my-3 lg:my-0">→{item.tag}</p>
              <div className="flex flex-row">
                <p>{item.description}</p>
              </div>
             </div>
             <div className="flex flex-row items-center justify-center mt-3 ">
                  <a
                    href={item.MyLink}
                    target="_blank" rel="noreferrer" className="flex justify-center p-2 bg-green-500 py-2 w-full rounded text-white font-semibold mt-4"
                  >
                    Visit- <i class="ri-external-link-fill"></i>
                  </a>
                </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllAiTools;
