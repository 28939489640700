import React from "react";
import footerlogo from "../components/AiLogo.png";
import { Link } from "react-router-dom";

const Footer = () => {

  const menus = [
    {
        label: "Home",
        href: '/'
    },
    {
        label: " AI News",
        href: '/news'
    },
    {
        label: "AI Jobs",
        href: '/jobs'
    },
    {
        label: "All AI Tools",
        href: '/allAiTools'
    },
    {
        label: "Contact us",
        href: '/contact'
    }
]

  return (
    <>
      <footer className="bg-orange-600 py-10 mt-6">
                <div className="w-10/12 mx-auto grid md:grid-cols-4 sm:grid-cols-1 md:gap-0 gap-8">
                <div className="pr-8">
                        <h1 className="text-white font-semibold text-2xl mb-3">About US</h1>
                        <p className="text-slate-50 mb-6">We're a team of AI enthusiasts dedicated to making AI accessible for everyone.<hr/> Join our growing social media community!</p>
                        <img 
                            src={footerlogo} alt=""
                            className="w-[100px]"
                        />
                    </div>
                    <div>
                        <h1 className="text-white font-semibold text-2xl mb-3">Links</h1>
                        <ul className="space-y-2 text-slate-50">
                            {
                                menus.map((item, index)=>(
                                    <li key={index}>
                                        <Link to={item.href}>{item.label}</Link>
                                    </li>
                                ))
                            }
                            <li><Link to="/login">Signup/Login</Link></li>
                            {/* <li><Link to="/signup">Signup</Link></li> */}
                        </ul>
                    </div>
                    <div>
                        <h1 className="text-white font-semibold text-2xl mb-3">Follow us</h1>
                        <ul className="space-y-2 text-slate-50">
                            <li><Link to="/">Facebook</Link></li>
                            <li><Link to="/">Youtube</Link></li>
                            <li><Link to="/">Twitter</Link></li>
                            <li><Link to="/">Linkedin</Link></li>
                            <li><Link to="/">Instagram</Link></li>
                        </ul>
                    </div>
                   
                    <div>
                        <h1 className="text-white font-semibold text-2xl mb-3">Contact us</h1>
                        <form className="space-y-4">
                            <input 
                                required
                                name="fullname"
                                className="bg-white w-full rounded p-3"
                                placeholder="Your name"
                            />

                            <input 
                                required
                                type="email"
                                name="email"
                                className="bg-white w-full rounded p-3"
                                placeholder="Enter email id"
                            />

                            <textarea 
                                required
                                name="message"
                                className="bg-white w-full rounded p-3"
                                placeholder="Message"
                                rows={3}
                            />

                            <button className="bg-green-500 text-white py-3 px-6 rounded">Submit</button>
                        </form>
                    </div>
                </div>
            </footer>
    </>
    // <footer className="bg-black text-white dark:bg-gray-900 mt-4">
    //   <div className="mx-auto md:w-full max-w-screen-xl ">
    //     <div className="lg:flex justify-between ">
    //       <div className="flex-1 mb-4 md:mb-0"> 
    //         <div href="#" className="flex items-center">
    //         <img className="h-16" src={footerlogo} alt="logo" />
    //           <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Explore AI Hub</span>
    //         </div><p className="sm:text-2xl lg:text-sm ml-4 dark:text-white">We're a team of AI enthusiasts dedicated to making AI accessible for everyone.<br />
    //         Join our growing social media community.</p>
    //       </div>
    //       <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 sm:text-2xl lg:text-sm">
    //         <div className="flex-1"> 
    //           <h2 className="my-6 sm:text-3xl lg:text-sm font-semibold text-white uppercase dark:text-white">Company</h2>
    //           <ul className="font-medium">
    //             <li className="mb-4">
    //               <a href="#" target="_blank" rel="noreferrer" className="hover:underline">Contact us<sup> <span className=" text-cyan-300">(soon)</span></sup></a>
    //             </li>
    //             <li className="mb-4">
    //               <a href="#" target="_blank" rel="noreferrer" className="hover:underline">Submit AI Tools <sup> <span className=" text-cyan-300">(soon)</span></sup></a>
    //             </li>
    //             <li>
    //               <a href="#" target="_blank" rel="noreferrer" className="hover:underline">Privacy Policy</a>
    //             </li>
    //           </ul>
    //         </div>
    //         <div className="flex-1">
    //           <h2 className="my-6 sm:text-3xl lg:text-sm font-semibold uppercase dark:text-white">Join us</h2>
    //           <ul className="font-medium">
    //             <li className="mb-4">
    //               <a href="https://www.instagram.com/exploreaihub_/" target="_blank" rel="noreferrer" className="hover:underline ">➔ Instagram</a>
    //             </li>
    //             <li className="mb-4">
    //               <a href="https://t.me/exploreaihub" target="_blank" rel="noreferrer" className="hover:underline">➔ Telegram</a>
    //             </li>
    //             <li className="mb-4">
    //               <a href="https://twitter.com/exploreaihub" target="_blank" rel="noreferrer" className="hover:underline">➔ Twitter</a>
    //             </li>
    //             <li className="mb-4">
    //               <a href="#" target="_blank" rel="noreferrer" className="hover:underline">➔ Youtube <sup> <span className=" text-cyan-300">(soon)</span></sup></a>
    //             </li>
    //           </ul>
    //         </div>
    //         {/* <div className="flex-1">
    //           <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
    //           <ul className="text-gray-500 dark:text-gray-400 font-medium">
    //             <li className="">
    //               <a href="#" className="hover:underline">Privacy Policy</a>
    //             </li> */}
    //             {/* <li>
    //               <a href="#" className="hover:underline">Terms &amp; Conditions</a>
    //             </li> */}
    //           {/* </ul>
    //         </div> */}
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    
  );
};

export default Footer;