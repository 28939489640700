import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Card from "./components/Card";
import Footer from "./components/Footer";
// import Searchfrom from './components/Searchform';
// import { BrowserRouter } from 'react-router-dom
import AllAiTools from "./components/AllAiTools";
import News from "./components/News";
import Jobs from "./components/Jobs";
import NotFound from "./components/NotFound";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Signup from "./components/Signup";

function App() {
  return (
    < >
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Card />} />
          <Route path="/allAiTools" element={<AllAiTools />} />
          <Route path="/news" element={<News />} />
          <Route path="/jobs" element={<Jobs />} />
          {/* NotFound is for universal not found page */}
          <Route path="*" element={<NotFound />} /> 
          <Route path="contact" element={<Contact/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;