import React from "react";

const Jobs = () => {
  const jobData = [
    {
      title: "AI Marketing Technology Manager (US)",
      company: "Highmark Health",
      link: "https://careers.highmarkhealth.org/explore-jobs/job/j237786-artificial-intelligence-ai-marketing-technology-manager/"
    },
    {
      title: "Open-Source ML Release Engineer (Remote)",
      company: "Hugging Face",
      link: "https://apply.workable.com/huggingface/j/89E1BF68BF/?utm_source=www.neatprompts.com"
    },
    {
      title: "Computer Vision Engineer (Remote)",
      company: "Samsara",
      link: "https://www.samsara.com/company/careers/roles/5908137/?gh_jid=5908137"
    },
    {
      title: "Digital Marketing Strategist",
      company: "Qualcomm",
      link: "https://careers.qualcomm.com/careers/job/446698896274?domain=qualcomm.com&source=APPLICANT_SOURCE-6-104"
    },
    {
      title: "Generative AI Scientist",
      company: "Mission Cloud",
      link: "https://www.linkedin.com/jobs/view/3917163007/"
    },
    {
      title: "Data scientist",
      company: "Cloudflare",
      link: "https://www.linkedin.com/jobs/view/3920526491/"
    },
    {
      title: "Artificial Intelligence Specialist",
      company: "JIO ROBOTICS",
      link: "https://www.linkedin.com/jobs/view/3919753417/"
    },
    {
      title: "Account Associate, ChatGPT Enterprise.",
      company: "OpenAI",
      link: "https://jobs.ashbyhq.com/openai/3d6dfe5e-0011-4788-9ba7-3cdcfdf5cdf1"
    },
    {
      title: "RAI Commercialization Management Leader - Contractor.",
      company: "EY",
      link: "https://app.gignow.com/ey/job_postings/a153384c71f94a10808fad2d0c02277d"
    },
    {
      title: "Research Program Manager, GenAI.",
      company: "META",
      link: "https://www.metacareers.com/jobs/361961923298133"
    },
    {
      title: "Competitive Intelligence Advisor.",
      company: "Cigna Group",
      link: "https://jobs.thecignagroup.com/us/en/job/CIGNUS24004121EXTERNALENUS/Competitive-Intelligence-Advisor-Solutions-Digital-Disruptors-Hybrid"
    },
    {
      title: "Senior Machine Learning Engineer.",
      company: "Reddit",
      link: "https://boards.greenhouse.io/reddit/jobs/5936626"
    },
    {
      title: "Machine Learning Engineer (Remote)",
      company: "Coinbase",
      link: "https://www.aboutamazon.com/news/company-news/amazon-anthropic-ai-investment?utm_source=www.neatprompts.com&utm_medium=newsletter&utm_campaign=ai-studio-for-marketers"
    },
    {
      title: "Machine Learning Engineer, AI/ML",
      company: "Netflix",
      link: "https://jobs.netflix.com/"
    },
    
  ];

  return (
    <div className="sm:mt-32 lg:mt-32">
      <div className="flex flex-col justify-center items-center mt-10 text-center leading-10">
        <div className="text-5xl font-semibold lg:text-6xl leading-10 mt-8">
        Find Latest<span className="text-green-600">&nbsp;AI Jobs&nbsp;</span>
        </div>
        <p className="mt-2 font-mono leading-10">Find here latest Jobs</p>
      </div>
      <div className="flex-col lg:flex-row justify-center text-white items-center lg:space-x-10 mt-10">
        {jobData.map((article, index) => (
          <div key={index} className="flex items-center flex-wrap p-6 m-7 bg-black border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ">
            <div className="w-full">
              <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">{article.title}</h5>
              <p className="mb-3 font-normal dark:text-gray-400">💼 {article.company}</p>
            </div>
            <div>
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-white  rounded-lg focus:ring-4 focus:outline-none bg-green-500 hover:bg-green-800"
              >
                Apply Now
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Jobs;




// Main code.
// import React from "react";

// const Jobs = () => {
//   return (
//     <div className="mt-24 lg:mt-28">
//       <div className="flex flex-col justify-center items-center text-center">
//         <div className="text-3xl font-semibold lg:text-6xl leading-10">
//           Find Latest AI Jobs
//         </div>
//         <p className="mt-2 font-mono leading-10">Find here latest Jobs</p>
//       </div>
//       <div className="flex flex-wrap justify-center mt-10 p-5">
//         {/* Pair of Cards */}
//         <div className="flex flex-col lg:flex-row justify-center items-center space-y-10 lg:space-y-0 lg:space-x-10">
//           {/* Job 1 */}
//           <div className="flex items-center flex-wrap p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:w-1/2">
//             <div className="w-full">
//               <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
//                 Machine Learning Engineer (Remote)
//               </h5>
//               <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
//                 💼 Coinbase
//               </p>
//             </div>
//             <div>
//               <a
//                 href="https://www.aboutamazon.com/news/company-news/amazon-anthropic-ai-investment?utm_source=www.neatprompts.com&utm_medium=newsletter&utm_campaign=ai-studio-for-marketers"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//               >
//                 Apply Now
//                 <svg
//                   className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
//                   aria-hidden="true"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 14 10"
//                 >
//                   <path
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M1 5h12m0 0L9 1m4 4L9 9"
//                   />
//                 </svg>
//               </a>
//             </div>
//           </div>
//           {/* Job 2 */}
//           <div className="flex items-center flex-wrap p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:w-1/2">
//             <div className="w-full">
//               <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
//                 Machine Learning Engineer, AI/ML
//               </h5>
//               <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
//                 💼 Netflix
//               </p>
//             </div>
//             <div>
//               <a
//                 href="https://jobs.netflix.com/"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//               >
//                 Apply Now
//                 <svg
//                   className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
//                   aria-hidden="true"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 14 10"
//                 >
//                   <path
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M1 5h12m0 0L9 1m4 4L9 9"
//                   />
//                 </svg>
//               </a>
//             </div>
//           </div>
//            {/* Job 3 */}
//           <div className="flex items-center flex-wrap p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:w-1/2">
//             <div className="w-full">
//               <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
//                 Machine Learning Engineer, AI/ML
//               </h5>
//               <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
//                 💼 Netflix
//               </p>
//             </div>
//             <div>
//               <a
//                 href="https://jobs.netflix.com/"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//               >
//                 Apply Now
//                 <svg
//                   className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
//                   aria-hidden="true"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 14 10"
//                 >
//                   <path
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M1 5h12m0 0L9 1m4 4L9 9"
//                   />
//                 </svg>
//               </a>
//             </div>
//           </div>
//            {/* Job 4 */}
//           <div className="flex items-center flex-wrap p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 lg:w-1/2">
//             <div className="w-full">
//               <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
//                 Machine Learning Engineer, AI/ML
//               </h5>
//               <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
//                 💼 Netflix
//               </p>
//             </div>
//             <div>
//               <a
//                 href="https://jobs.netflix.com/"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//               >
//                 Apply Now
//                 <svg
//                   className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
//                   aria-hidden="true"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 14 10"
//                 >
//                   <path
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M1 5h12m0 0L9 1m4 4L9 9"
//                   />
//                 </svg>
//               </a>
//             </div>
//           </div>
//         </div>
        
//       </div>
//     </div>
//   );
// };

// export default Jobs;
